import React from 'react'
import Navbar from "../Componets/Navbar";
import Footer from "../Componets/Footer";
import AboutUsComponent from "../Componets/AboutUsComponet";

const About = () => {
  return (
    <div className='Contact Container'>
      <div className='Contact-Navbar'>
        <Navbar/>
      </div>
      <div className='Contact-Navbar mt-[100px]'>
        <AboutUsComponent/>
      </div>
      <div className = 'Contact_Footer'>
        <Footer/>
      </div>
  </div>
  )
}

export default About