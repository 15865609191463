import React from 'react'

const Intergration = () => {
  return (
    <div>
        <div className="bg-blue-500 lg:h-[50px] h-[500px] px-[50px] py-[100px] lg:p-[300px] lg:px-[400px] text-white flex items-center justify-center">
            <h1 className="lg:text-6xl text-4xl md:text-6xl font-bold lg:text-center">Elevate finance through expert Salesforce Intergration—innovation in the cloud.</h1>
        </div>

        <div className="bg-white h-[300px]  py-[50px] px-[50px] lg:px-[500px] text-[#313131] flex items-center justify-center">
            <p className="lg:text-2xl mt-10 text-lg font-bold lg:text-center">
                Integrate Salesforce for a Future-Ready Business. Elevate your business with our comprehensive Salesforce integration services, covering all aspects of Salesforce cloud functionalities, to propel your digital transformation forward.
            </p>
        </div>
        <hr class=" border-gray-200 sm:mx-auto dark:border-gray-700" />

        <div className="bg-white mt-[100px] lg:py-8 lg:px-[500px] px-[50px] text-[#313131]">
            <h2 className="text-4xl font-bold mb-4 lg:text-center">We help our clients intergrate other information systems with Salesforce CRM</h2>
            <p className="text-lg pt-4 lg:text-center">
                Whether you're utilizing Enterprise Resource Planning (ERP) Systems, Business Intelligence (BI) Systems, Financial Management Systems, Document Management Systems (DMS), or Project Management Systems, Digital FinTech's expert team can seamlessly Intergrate those information systems with Salesforce to align with your unique business needs.
            </p>
        </div>
        <div className="bg-blue-500 mt-[100px] py-8 lg:py-8 lg:px-[500px] px-[50px] text-white">
          <h2 className="text-5xl font-bold mb-4 lg:text-center">Integrate Salesforce with the apps you use every day.</h2>
        </div>
        <div className="bg-white mt-[50px] lg:py-8 lg:px-[500px] px-[50px] text-[#313131]">
            <p>How do you make Salesforce even better? Integrate it with the apps you already use. From productivity to marketing to collaboration and beyond, now you can connect your Salesforce to the other tools you need to run your business. <strong>MuleSoft</strong> is Salesforce's integration and automation technology and offers connectivity solutions for all of your apps.</p>
        </div>
        <div className="grid grid-cols-1 py-10 my-4 gap-4 lg:grid-cols-3 lg:gap-8 px-10 lg:px-[350px]">
            <div className="bg-blue-500 p-8 text-white mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Jira</h2>
                <p className="text-md">
                  Integrate Jira and Salesforce and make it easier to monitor the progress of your projects. 
                </p>
            </div>

            <div className="bg-gray-300 p-8 mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Slack</h2>
                <p className="text-md">
                  Increase team collaboration and productivity with the Slack and Salesforce integration. 
                </p>
            </div>

            <div className="bg-green-500 p-8 text-white mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">HelloSign</h2>
                <p className="text-md">
                  Use this integration to simplify the process for preparing and tracking e-signature documents.
                </p>
            </div>

            <div className="bg-yellow-300 p-8 mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">G-Suite</h2>
                <p className="text-md">
                  Streamline your team's workflow and increase productivity by syncing G Suite by Google Cloud with Salesforce. 
                </p>
            </div>

            <div className="bg-indigo-500 p-8 text-white mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Quickbooks</h2>
                <p className="text-md">
                Increase sales and generate accurate financial data by integrating Quickbooks and Salesforce.
                </p>
            </div>

            <div className="bg-purple-300 p-8 mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Microsoft 365</h2>
                <p className="text-md">
                  Microsoft 365 Office Suite, a bundle of productivity tools that includes popular applications such as Word, Excel, PowerPoint, and Outlook.
                </p>
            </div>
        </div>

        <div className="bg-blue-500 lg:h-[50px] h-[500px] px-[50px] py-[100px] lg:p-[300px] lg:px-[400px] text-white flex items-center justify-center">
            <h1 className="lg:text-6xl text-4xl md:text-6xl font-bold lg:text-center">Our Salesforce Intergration Services.</h1>
        </div>

        <div class="bg-white p-4 rounded-lg shadow-xl lg:px-[250px] py-8 mt-12">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-12 px-2 xl:px-12 mt-4 pb-12">
            
            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">System Integration Strategy</h4>
                    <p class="text-gray-600 my-2">Work with clients to develop a comprehensive integration strategy that aligns with their business objectives and technology landscape. Identify key systems and data sources that need to be integrated with Salesforce to enable seamless data flow and connectivity.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">API Integration</h4>
                    <p class="text-gray-600 my-2">Utilize Salesforce APIs (such as SOAP, REST, Bulk, Streaming) to integrate Salesforce with other business systems, applications, and data sources. Develop custom integration solutions to synchronize data in real-time or batch mode, ensuring data consistency and accuracy across systems.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Middleware Integration</h4>
                    <p class="text-gray-600 my-2">Implement middleware platforms (such as MuleSoft, Dell Boomi, Informatica) to orchestrate complex integrations between Salesforce and external systems. Configure middleware solutions to handle data transformation, routing, and orchestration, enabling interoperability between disparate systems.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Legacy System Integration</h4>
                    <p class="text-gray-600 my-2">Assist clients in integrating Salesforce with legacy on-premises systems, such as ERP, CRM, and custom databases. Develop integration solutions using middleware or custom integration code to bridge the gap between Salesforce and legacy systems, enabling data exchange and process automation.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Cloud-to-Cloud Integration</h4>
                    <p class="text-gray-600 my-2"> Facilitate integration between Salesforce and other cloud-based applications and platforms, such as Microsoft Azure, Google Cloud Platform, and AWS. Configure secure API connections and data mappings to enable seamless data exchange and business process automation across cloud environments.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Data Migration and Synchronization</h4>
                    <p class="text-gray-600 my-2">Plan and execute data migration projects to transfer data from legacy systems to Salesforce or vice versa. Implement data synchronization solutions to ensure data consistency and integrity between Salesforce and external systems, minimizing data duplication and discrepancies.</p>
                </div>
            </div>
            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Custom Integration Solutions</h4>
                    <p class="text-gray-600 my-2">Develop custom integration solutions tailored to clients' unique business requirements and technical constraints. Leverage Salesforce development tools (such as Apex, Visualforce, Lightning Web Components) to build custom integrations that address specific data transformation, validation, and business logic needs.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Continuous Integration and Deployment (CI/CD)</h4>
                    <p class="text-gray-600 my-2">Implement CI/CD pipelines and automation frameworks to streamline the development, testing, and deployment of integration solutions. Ensure reliable and scalable integration processes by adopting best practices for version control, automated testing, and release management.</p>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Intergration