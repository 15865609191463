import React from 'react'
import SalesforceClouds from '../Assets/Saleforce-Clouds.png'
import Marketing from '../Assets/Marketing.jpg'
import Sales from '../Assets/Sales.jpg'
import Service from '../Assets/Service.jpg'
import Data from '../Assets/Data.jpg'

const SalesforceCRM = () => {
  return (
    <div>
        <div className="p-8 lg:mx-[200px]">
            <h2 className="text-2xl text-[#032d60] lg:text-4xl pt-[50px] lg:text-center font-bold mb-4">What is Salesforce?</h2>
            <p className="text-gray-700 lg:text-center lg:mx-[200px]">
                Salesforce is a company that makes cloud-based software designed to help businesses find more prospects, close more deals, and wow customers with amazing service.
            </p>
            <p className="text-gray-700 mt-4 lg:text-center lg:mx-[200px]">
                Customer 360, is their complete suite of products, unites your sales, service, marketing, commerce, and IT teams with a single, shared view of customer information, helping you grow relationships with customers and employees alike.
            </p>
            <div className="flex flex-col lg:items-center my-6">
                <img src={SalesforceClouds} alt="Salesforce Products" className="my-10" />
            </div>

            <h2 className="text-2xl text-[#032d60] lg:text-4xl lg:text-center font-bold mb-4">What does Salesforce do?</h2>
            <p className="text-gray-700 lg:text-center lg:mx-[200px]">
                It helps teams work better together. Your business may use a single Customer 360 app, or a combination of many. By improving team communications and productivity, businesses drive greater success.
            </p>

            <div className="bg-gray-100 p-8 rounded-md mt-[100px] shadow-md lg:mx-[100px]">

            <div className="">
            <div className="lg:flex lg:justify-center lg:items-start">
                <div className="lg:w-1/3 lg:mr-8">
                    <img src={Marketing} alt="Marketing" className="rounded-md shadow-md mb-4 lg:mb-0 lg:mr-4" />
                </div>
                <div className="lg:w-2/3">
                    <h4 className="text-[18px] text-[#032d60] lg:text-2xl pt-[50px] lg:text-left lg:mx-[130px] font-bold mb-4">Connect the dots between marketing and sales.</h4>
                    <p className="text-gray-700 lg:text-left lg:mx-[130px]">
                    Use the Marketing Cloud app to send targeted, personalised messages to prospects, right where they are most likely to engage with them. When customers click on your ad or website, an automated message is sent to sales, notifying the team of a new lead.
                    </p>
                </div>
                </div>

                <div className="lg:flex lg:justify-center lg:items-start mt-8">
                <div className="lg:w-1/3 lg:mr-8">
                    <img src={Sales} alt="Sales" className="rounded-md shadow-md mb-4 lg:mb-0 lg:mr-4" />
                </div>
                <div className="lg:w-2/3">
                    <h4 className="text-[18px] text-[#032d60] lg:text-2xl pt-[50px] lg:text-left lg:mx-[130px] font-bold mb-4">Share more information to close more deals.</h4>
                    <p className="text-gray-700 lg:text-left lg:mx-[130px]">
                    Sales Cloud lets everyone on the sales team see and share all the pertinent customer information, giving reps everything they need to close a deal. AI-driven prompts let you know when it’s the best time to take a specific action to drive a deal forward.
                    </p>
                </div>
                </div>

                <div className="lg:flex lg:justify-center lg:items-start mt-8">
                <div className="lg:w-1/3 lg:mr-8">
                    <img src={Service} alt="Servicc" className="rounded-md shadow-md mb-4 lg:mb-0 lg:mr-4" />
                </div>
                <div className="lg:w-2/3">
                    <h4 className="text-[18px] text-[#032d60] lg:text-2xl pt-[50px] lg:text-left lg:mx-[130px] font-bold mb-4">Make service the secret to your success.</h4>
                    <p className="text-gray-700 lg:text-left lg:mx-[130px]">
                    Because your support team can see all your customers’ information, team members can better deliver those essential surprise-and-delight moments. And big issues get resolved faster with cross-company case swarming.
                    </p>
                </div>
                </div>

                <div className="lg:flex lg:justify-center lg:items-start mt-8">
                <div className="lg:w-1/3 lg:mr-8">
                    <img src={Data} alt="Data" className="rounded-md shadow-md mb-4 lg:mb-0 lg:mr-4" />
                </div>
                <div className="lg:w-2/3">
                    <h4 className="text-[18px] text-[#032d60] lg:text-2xl pt-[50px] lg:text-left lg:mx-[130px] font-bold mb-4">Let the data tell you where to focus.</h4>
                    <p className="text-gray-700 lg:text-left lg:mx-[130px]">
                    Tableau analytics sends an automated notice when a significant or atypical trend or a new service issue is detected. Easy-to-use dashboards make it simple for anyone to dig into the data to learn more and instantly take action.
                    </p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default SalesforceCRM