import React from 'react'
import Salesforce_logo from "../Assets/Salesforce-Logo.png";

const Salesforce = () => {
  return (
    <div className="bg-white p-4 flex items-center justify-center">
        <p className="text-gray-700 text-lg font-semibold mr-2">We are official</p>
            <img src= {Salesforce_logo} alt="Salesforce Logo" className="h-8 w-auto mr-2" />
        <p className="text-gray-700 text-lg font-semibold ml-0">Partner</p>
    </div>
  )
}

export default Salesforce