import React from 'react'
import Navbar from '../Componets/Navbar'
import Footer from '../Componets/Footer'
import Consultations from '../Componets/Consultation'

const Consultation = () => {
  return (
    <div className='Contact Container'>
        <div className='Contact-Navbar'>
            <Navbar/>
        </div>
        <div className='Contact-Navbar mt-[100px]'>
            <Consultations/>
        </div>
        <div className = 'Contact_Footer'>
            <Footer/>
        </div>
    </div>
  )
}

export default Consultation