export default function Example() {

  return (
    <section class="bg-gradient-to-r from-slate-900 to-slate-700">
    <div class="mx-auto max-w-screen-xl py-64 lg:flex lg:h-screen lg:items-center lg:py-32 px-10">
      <div class="mx-auto max-w-3xl text-center">
      <h1
        class="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl"
      >
     Are you ready to optimize

     <span class="sm:block"> your business with FinTech? </span>
     </h1>

     <p class="mx-auto mt-4 text-white max-w-xl sm:text-xl/relaxed">
     Through a customized, on-the-ground, adaptive operations strategy implementation based on FinTech best practices and data analytics.
     </p>

     <div class="mt-8 flex flex-wrap justify-center gap-4">
       <a
         class="block w-half rounded border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
       href="./Contact"
        >
          Get Started
        </a>

     <a
       class="block w-half rounded border border-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
       href="./Salesforce"
     >
       Learn More
     </a>
   </div>
 </div>
</div>
</section>
  )
}
