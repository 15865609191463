import React from 'react'
import { FaTools, FaUserCheck, FaLightbulb, FaChartBar, FaUsers } from 'react-icons/fa';
import { FaCogs, FaChartLine, FaCode, FaCoins, FaCloud, FaHeadset, FaShoppingCart } from 'react-icons/fa';


const AboutUsComponet = () => {
  return (
        <section className="bg-gray-100 pb-10">
            <div className="bg-blue-500 lg:h-[50px] h-[500px] px-[50px] py-[100px] lg:p-[300px] lg:px-[400px] text-white flex items-center justify-center">
                <h1 className="lg:text-6xl text-4xl md:text-6xl font-bold lg:text-center">Welcome to Digital FinTech</h1>
            </div>
            <div className="max-w-screen-xl mx-auto">
            {/* Section 1: Introduction */}
            <div className="my-8">
                <p className="text-gray-700 text-left lg:text-justify mx-10 lg:mx-[200px] py-10">
                    The evolution of banking is increasingly digital. Technologies like online payments, mobile banking, and e-commerce, which were in their early stages two decades ago, are now easily accessible with just a tap — a transformation embraced by both emerging startups and established financial institutions. And we are here to offer a new transformation through
                </p>
            </div>

            <div className="mx-10 lg:mx-[200px] flex flex-wrap justify-center">
                <div className="flex items-center flex-col mx-10 my-6">
                    <FaTools className="text-gray-500 text-3xl" />
                    <div className='font-bold mt-4'>
                    Quality Services
                    </div>
                </div>
                <div className="flex items-center flex-col mx-10 my-6">
                    <FaUserCheck className="text-gray-500 text-3xl" />
                    <div className='font-bold mt-4'>
                    Clients Satisfaction
                    </div>
                </div>
                <div className="flex items-center flex-col mx-10 my-6">
                    <FaLightbulb className="text-gray-500 text-3xl" />
                    <div className='font-bold mt-4'>
                    Strategic Planning
                    </div>
                </div>
                <div className="flex items-center flex-col mx-10 my-6">
                    <FaUsers className="text-gray-500 text-3xl" />
                    <div className='font-bold mt-4'>
                    Experienced Staff
                    </div>
                </div>
                <div className="flex items-center flex-col mx-10 my-6">
                    <FaChartBar className="text-gray-500 text-3xl" />
                    <div className='font-bold mt-4'>
                    Efficient Operations
                    </div>
                </div>
            </div>

            <div className="">
                <p className="text-gray-700 text-left lg:text-justify mx-10 lg:mx-[200px] py-4">
                As the shift toward digitalization in banking involves a comprehensive move from traditional in-branch services to a top-down offering of online and digital services. This encompasses the integration of digital systems, customer experience platforms, apps, and infrastructure. Key technologies at the forefront of this transformation include blockchain, artificial intelligence (AI), cloud computing, machine learning, and the collection, management, and analysis of customer data. The rise of FinTech, characterized by innovative technologies supporting banking and financial services, has disrupted the sector, drawing the attention of regulators and prompting the establishment of stricter regulatory standards.
                </p>
            </div>
        </div>
           {/*} <div>
                <div className="bg-blue-500 py-[100px] px-[50px] text-white text-center mt-10">
                    <h1 className="lg:text-6xl text-4xl md:text-6xl font-bold lg:text-center">TECHNICAL EXPERTISE</h1>
                    <p class="text-2xl text-left font-bold lg:text-center text-white-700 mb-4 mx-10 mt-10 pb-10">
                        We have a highly skilled technical team proficient in Salesforce, specializing in various areas including:
                    </p>
                <ul className="flex flex-wrap lg:justify-center items-center font-bold text-lg text-[#c2c2c2] mb-6 mx-10">
                    <li className="flex items-center mr-6"><FaCogs className="mr-2" />Salesforce Architecture</li>
                    <li className="flex items-center mr-6"><FaChartLine className="mr-2" />Business Analysis</li>
                    <li className="flex items-center mr-6"><FaCode className="mr-2" />Development</li>
                    <li className="flex items-center mr-6"><FaCoins className="mr-2" />Financial Services Cloud</li>
                    <li className="flex items-center mr-6"><FaCloud className="mr-2" />Experience Cloud</li>
                    <li className="flex items-center mr-6"><FaHeadset className="mr-2" />Service Cloud</li>
                    <li className="flex items-center"><FaShoppingCart className="mr-2" />Sales Cloud</li>
                </ul>
                </div>
  </div>*/}
    </section>
  )
}

export default AboutUsComponet