import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto mt-[150px] mb-10 p-8 px-10 lg:px-[150px] bg-white rounded-lg shadow-md">
      <h2 className="text-3xl text-[#032d60] font-bold mb-6">Privacy Policy</h2>
      <p className="text-gray-700 leading-relaxed">
      <strong>Digital FinTech (Pty) Ltd</strong> is a registered entity with CIPC. All references in this Privacy Notice (“notice” or “privacy notice”) to "us", "we", "our" are also deemed to include, unless stated otherwise, <strong>Digital FinTech (Pty) Ltd.</strong>
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      We are committed to protecting your privacy and complying with applicable data protection and privacy laws. This notice provides you with information about our online collection and use of personal information. Personal information includes any information by which you can be identified and which relates to you as an identifiable individual such as your name, physical and postal addresses, e-mail address, race, sex, age and/or internet address of the domain from which you are visiting. The terms of this notice apply to the website and are subject to the Terms of Use.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      <strong>Collection of personal information</strong> - While you use our website, we may collect your personal information or you may provide it to us for example, when you request us to contact you, you may provide your name, email address or telephone number. We make use of third-party tools to assist us in tracking users and their activity on our site, this allows us to provide a better and more personalized experience.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      <strong>Use of Cookies</strong> - If you use this website, we may collect technical information through the use of cookies. For example, what pages of the website were visited by each user and any documents downloaded. The purpose of cookies is to enable us to provide a more valuable online experience to you and enhance your future visits to our website. Cookies are programs that we transfer to your computer’s hard drive and which only store information from your browser, they cannot access data on your hard drive.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      Cookies cannot gather information or function on their own. They cannot collect any personal information about you or your machine and cannot gather data or information about what you do on the Internet. Cookies are text files that cannot transfer viruses to your computer or mobile device. You can set your browser to notify you if cookies are to be transferred, or to reject cookies, but this may limit or prevent your use of some of our web pages.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      <strong>Use of personal information</strong> - We collect and use your personal information to better understand our clients, to display content that is relevant to you, to operate and improve the website and assist us with website administration, to comply with legislative requirements, and for any purposes deemed necessary or required by us to enforce the Terms of Use of the website.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      <strong>Disclosure of personal information</strong> - We do not sell, exchange, rent or trade your personal information with anyone. We may share your personal information with third party service providers who perform services on our behalf, in any country in the world. We will need to disclose your personal information to our employees, who require it to do their jobs. We reserve the right to disclose your personal information, without notice, if required to do so by law.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      <strong>Security of personal information</strong> - Whilst we cannot guarantee the absolute security of your personal information, we take reasonably practical and appropriate measures to keep it secure by following generally accepted security policies and rules, including the use of encryption technology. However, we do not accept any liability for any unauthorised access or loss of personal information despite our best endeavours, and accordingly you indemnify us against such unauthorised access or loss of personal information. Personal information collected by us may be processed and stored in South Africa or any other country in which we, or our service providers, or agents maintain facilities, and by using this website, you consent to any such transfer or information outside of your country.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      <strong>Third Party Sites</strong> - If you use our website and follow a link from it to another website, different privacy policies may apply. We assume no responsibility for the policies or practices of such linked websites. Prior to submitting any personal information to a website, you should read the privacy notice applicable to that website.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      <strong>Access to and correction of personal information</strong> You have the right to request your personal information that we hold, in order to update, correct, verify or delete (within reasonable and legal boundaries) such information.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      <strong>Minors</strong> - We do not intend to solicit or collect personal information from anyone under the age of 18 without the permission of their parent or guardian. If you are under the age of 18, you should not use, or enter information on, this website.
      </p>
      <p className="text-gray-700 leading-relaxed mt-4">
      <strong>Disclaimer</strong> - We intend to take reasonable precautions to abide by this privacy notice. Nevertheless in the event that we do not comply with all terms in this notice, you agree, by using this website and by submitting information to this website, that your sole and exclusive remedy against us will be to have us promptly correct the handling of your information in the future to accord with this privacy notice, and to notify anyone to whom we have directly transferred any information, of the correct information or to cease using such information.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
