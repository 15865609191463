import React from 'react'

import Navbar from '../Componets/Navbar'
import Footer from '../Componets/Footer'
import SalesforceCRM from '../Componets/SalesforceCRM'

const Salesforce = () => {
  return (
    <div className='Contact Container'>
        <div className='Contact-Navbar'>
            <Navbar/>
        </div>
        <div className='Contact-Navbar mt-[100px]'>
            <SalesforceCRM/>
        </div>
        <div className = 'Contact_Footer'>
            <Footer/>
        </div>
    </div>
  )
}

export default Salesforce