import React from 'react'

const Consultation = () => {
  return (
    <div>
        <div className="bg-blue-500 lg:h-[50px] h-[500px] px-[50px] py-[100px] lg:p-[300px] lg:px-[400px] text-white flex items-center justify-center">
            <h1 className="lg:text-6xl text-4xl md:text-6xl font-bold lg:text-center">Elevate finance through expert Salesforce consultation—innovation in the cloud.</h1>
        </div>

        <div className="g-white h-[300px]  py-[50px] px-[50px] lg:px-[500px] text-[#313131] flex items-center justify-center">
            <p className="lg:text-2xl mt-10 font-bold text-lg lg:text-center">
                Build a Future-Ready Business with Salesforce CRM. We've got the tools to help you build a business for the future. Our consulting services encompass the full spectrum of Salesforce cloud disciplines, driving your digital transformation.
            </p>
        </div>
        <hr class="border-gray-200 sm:mx-auto dark:border-gray-700" />
        <div className="bg-white mt-[100px] lg:py-8 lg:px-[500px] px-[50px] text-[#313131]">
            <h2 className="text-4xl font-bold mb-4 lg:text-center">We are consultant for the following Salesforce Products</h2>
            <p className="text-lg pt-4 lg:text-center">
                Whether you're utilizing Sales Cloud, Service Cloud, Marketing Cloud, or a bespoke Salesforce solution, Digital FinTech's expert team can seamlessly customize it to align with your unique business needs.
            </p>
        </div>
        <div className="grid grid-cols-1 py-10 my-4 gap-4 lg:grid-cols-3 lg:gap-8 px-10 lg:px-[350px]">
            <div className="bg-blue-500 p-8 text-white mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Sales Cloud</h2>
                <p className="text-md">
                Build lasting relationships and increase revenue, while educating and empowering your customers, clients, members, employees, and partners.
                </p>
            </div>

            <div className="bg-gray-300 p-8 mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Service Cloud</h2>
                <p className="text-md">
                Make each and every customer experience feel like a one-to-one interaction.
                </p>
            </div>

            <div className="bg-green-500 p-8 text-white mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Marketing Cloud</h2>
                <p className="text-md">
                We know Salesforce and the extensive use cases and benefits of its best-in-class marketing automation solutions, Marketing Cloud and Pardot.
                </p>
            </div>

            <div className="bg-yellow-300 p-8 mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Experience Cloud</h2>
                <p className="text-md">
                Formerly Community Cloud, Salesforce's Experience Cloud helps you build relationships, increase revenue, and empower users with the right information at the right time.
                </p>
            </div>

            <div className="bg-indigo-500 p-8 text-white mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Data Cloud</h2>
                <p className="text-md">
                Harness the power of data to drive customer insights, enhance marketing campaigns, and make more informed data-driven decisions.
                </p>
            </div>

            <div className="bg-purple-300 p-8 mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Financial Services Cloud</h2>
                <p className="text-md">
                Ensure client satisfaction, create personalized journeys, and drive growth and innovation with the leading platform for financial services organizations.
                </p>
            </div>
        </div>
        <div className="bg-blue-500 lg:h-[50px] h-[500px] px-[50px] py-[100px] lg:p-[300px] lg:px-[400px] text-white flex items-center justify-center">
            <h1 className="lg:text-6xl text-4xl md:text-6xl font-bold lg:text-center">Our Consulting Services.</h1>
        </div>

        <div class="bg-white p-4 rounded-lg shadow-xl lg:px-[250px] py-8 mt-12">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-12 px-2 xl:px-12 mt-4 pb-12">
            
            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Salesforce Implementation</h4>
                    <p class="text-gray-600 my-2">Assist clients in setting up and configuring Salesforce according to their specific business needs and requirements. This includes customizing objects, fields, workflows, and automation processes to optimize their Salesforce instance for maximum efficiency.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Salesforce Custom Development</h4>
                    <p class="text-gray-600 my-2">Provide custom development services to extend the functionality of Salesforce beyond its out-of-the-box capabilities. Develop custom Apex code, Visualforce pages, Lightning components, and integrations to address unique business requirements and improve user experience.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Salesforce Training and Adoption</h4>
                    <p class="text-gray-600 my-2">Offer training programs and workshops to educate clients' users on how to effectively use Salesforce. Provide ongoing support and guidance to drive user adoption and ensure clients maximize the value of their Salesforce investment.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Salesforce Health Check and Optimization</h4>
                    <p class="text-gray-600 my-2"> Perform comprehensive audits of clients' Salesforce orgs to identify areas for improvement and optimization. Offer recommendations and implement best practices to enhance system performance, data quality, and user adoption.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Salesforce Migration and Upgrades</h4>
                    <p class="text-gray-600 my-2">Assist clients in migrating data from legacy systems to Salesforce and upgrading their Salesforce instance to the latest version. Ensure a smooth transition with minimal disruption to business operations and data integrity.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Salesforce Analytics and Reporting</h4>
                    <p class="text-gray-600 my-2">Design and configure Salesforce Analytics solutions to empower clients with actionable insights and data-driven decision-making capabilities. Develop custom reports, dashboards, and analytics solutions to visualize key metrics and performance indicators.</p>
                </div>
            </div>
            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Salesforce Marketing Automation</h4>
                    <p class="text-gray-600 my-2">Provide expertise in configuring and optimizing Salesforce Marketing Cloud or Pardot to automate marketing processes, manage campaigns, and nurture leads effectively. Help clients leverage marketing automation tools to drive customer engagement and generate more leads.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Salesforce AppExchange Solutions</h4>
                    <p class="text-gray-600 my-2">Assist clients in selecting and implementing third-party Salesforce AppExchange apps and solutions to extend the functionality of their Salesforce org. Offer guidance on app selection, configuration, and integration to meet specific business needs.</p>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Consultation