import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import App from './App.js';
import Contact from './Pages/Contact.js';
import About from './Pages/About.js';
import Notfound from './Pages/NotFound.js';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Consultation from './Pages/Consultation.js';
import Intergration from './Pages/Intergration.js';
import Maintanance from './Pages/Maintanance.js';
import Salesforce from './Pages/Salesforce.js';

const router = createBrowserRouter([
      {
      path: "/",
      element: <App/>,
      },
      {
        path: "/Contact",
        element: <Contact/>,
      },
      {
        path: "/About",
        element: <About/>,
      },
      {
        path: "/Home",
        element: <App/>,
      },
      {
        path: "/Privacy-Policy",
        element: <PrivacyPolicy/>,
      },
      {
        path: "/Salesforce-Intergration",
        element: <Intergration/>,
      },
      {
        path: "/Salesforce-Consultation",
        element: <Consultation/>,
      },
      {
        path: "/Salesforce-Maintanance",
        element: <Maintanance/>,
      },
      {
        path: "/Salesforce",
        element: <Salesforce/>,
      },
      {
        path: "*",
        element: <Notfound/>,
      },
  ]);

ReactDOM.render( <RouterProvider router={router} />, document.getElementById('root'));