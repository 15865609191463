
import { FaLinkedin, FaFacebook, FaInstagram } from 'react-icons/fa';
import Logo from "../Assets/LOGO.png";

function Footer() {
  return (
    

<footer class="bg-white rounded-0 shadow dark:bg-gray-900 m-0">
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
            <div class="flex items-center justify-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                <img src={Logo} class="h-16" alt="Digital Fintech Logo" />
            </div>
            <ul class="flex flex-wrap items-center justify-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                <li>
                    <a href="./About" class="hover:underline me-4 md:me-6">About</a>
                </li>
                <li>
                    <a href="./Privacy-Policy" class="hover:underline me-4 md:me-6">Privacy Policy</a>
                </li>
                <li>
                    <a href="./Salesforce-Consultation" class="hover:underline me-4 md:me-6">Consultation</a>
                    <a href="./Salesforce-Intergration" class="hover:underline me-4 md:me-6">Intergration</a>
                    <a href="./Salesforce-maintanance" class="hover:underline me-4 md:me-6">Maintanance</a>
                </li>
                <li>
                    <a href="./Contact" class="hover:underline">Contact</a>
                </li>
            </ul>
            <div class="flex items-center justify-center space-x-4">
                <a href="./" class="text-gray-500 hover:text-blue-500"     target="_blank" rel="noopener noreferrer">
                  <FaLinkedin />
                </a>
                <a href="./" class="text-gray-500 hover:text-blue-500" target="_blank" rel="noopener noreferrer">
                  <FaFacebook />
                </a>
                <a href="./" class="text-gray-500 hover:text-blue-500" target="_blank" rel="noopener noreferrer">
                  <FaInstagram />
                </a>
            </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="./" class="hover:underline">Digital FinTech™</a>. All Rights Reserved.</span>
    </div>
</footer>




  );
}

export default Footer;