import React from 'react'
import Navbar from "../Componets/Navbar"
import Footer from '../Componets/Footer'
import PrivacyPolicyCom from '../Componets/PrivacyPolicy'

const PrivacyPolicy = () => {
  return (
    <div>
        <div>
            <Navbar/>
        </div>
        <div>
            <PrivacyPolicyCom/>
        </div>
        <div>
            <Footer/>
        </div>
        
    </div>

  )
}

export default PrivacyPolicy    