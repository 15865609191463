import React from 'react'
import About from "../Assets/About.jpg"
import Transformation from "../Assets/Transformation.jpg"

const HomeAbout = () => {
  return (
    <div className="bg-gray-100 pt-16">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold mb-6 text-[#032d60] break-after-column px-10  lg:text-center pt-[30px]">About Us</h2>
      <p className="text-gray-700 leading-relaxed px-10 lg:px-[150px] lg:text-center lg:pb-[70px]" >
      Digital Fingerprint, is equipped with advanced technical and business capabilities and capacity to service the financial sector with the deployment of cloud systems, identification of challenges, development and implementation of solutions, as well as training and supporting the client during technology adoption stages.
      </p>
    </div>

    <div className="flex flex-col md:flex-row bg-[#e9e8e8] p-8 px-10 py-[100px] my-10 lg:mx-[200px] mx-10 rounded-lg shadow-md">
      <div className="md:w-1/3 mb-8 md:mb-0 md:mr-8 lg:pl-[100px] flex items-center justify-center">
        <img
          className="rounded-[15px] shadow-md mx-auto md:mx-0"
          src= {About}
          alt="Sample"
        />
      </div>
      <div className="md:w-2/3 lg:pl-[150px] lg:pr-[100px]">
        <div className="mb-6">
          <h3 className="text-1xl text-[#032d60] font-bold mb-2">TECHNICAL EXPERTISE</h3>
          <p className="text-gray-700">
            We have a highly skilled technical team skilled in Salesforce (Architecture,
            Business Analysts, Development, etc. with specialization in the Financial
            Services, Experience, Service and Sales Clouds. We have specialists in Software Development, Artificial Intelligence, Innovation, ESG and Engineering.
          </p>
        </div>
        <div className="mb-6">
          <h3 className="text-1xl text-[#032d60] font-bold mb-2">DIGITAL FINGERPRINT ACADEMY</h3>
          <p className="text-gray-700">
            Our academy is both an investment into our digital skills pipeline and a
            commitment to bridging the digital skills gap in South Africa. The academy
            offers technical training, coaching and mentorship as well as corporate
            experience to a pool of talented young people. Read more
          </p>
        </div>
        <div>
          <h3 className="text-1xl text-[#032d60] font-bold mb-2">CORPORATE SOCIAL INVESTMENTS (CSI)</h3>
          <p className="text-gray-700">
            We sponsor community programs with special focus on skills development, 
            such as the TeenPreneur HUB, a skills development program fully funded by 
            Digital Fingerprint focusing on Entrepreneurship, Technology, Media and Arts. More info: 
          </p>
          <a  href="https://teenagers.org.za/" target="_blank" rel="noopener noreferrer"  className="text-blue-500 mt-2 inline-block">teenagers.org.za</a>
        </div>
      </div>
    </div>
    
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 px-10 lg:px-[200px]">
        <div class="h-55 rounded-lg bg-gray-200">
          <h3 className='text-1xl font-bold px-10 mb-2 mt-10'>Our Mission</h3>
          <p className='text-gray-700 px-10 pb-10'>Optimise business operations through, customised, on-the-ground, adaptive business strategy implementation models informed by data analytics.</p>
        </div>
        <div class="h-55 rounded-lg bg-gray-200">
        <h3 className='text-1xl font-bold mb-2 px-10 mt-10 '>Our Vision</h3>
          <p className='text-gray-700 px-10 pb-10'>To be a catalyst for efficient business operations..</p>
        </div>
        <div class="h-55 rounded-lg bg-gray-200">
        <h3 className='text-1xl font-bold mb-2 px-10 mt-10'>Our Values</h3>
          <p className='text-gray-700 px-10 pb-10'>We champion an environment of honesty, transparency, fairness and high moral standards.</p>
        </div>
    </div>
    <div className="flex flex-col md:flex-row bg-gradient-to-r from-blue-200 to-cyan-200 px-10 py-[100px] mt-10 mb-[0px] lg:px-[100px] rounded-lg shadow-md">
        <div className="flex  flex-col md:w-1/3 mb-8 md:mb-0 md:mr-8 lg:pl-[100px]">
          <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-6 break-after-column  text-left">Digital transformation</h2>
          <p className="text-gray-700 leading-relaxed mb-10 text-left" >
          We offer expertise in the integration of technology into all areas of a business, fundamentally transforming the operation and delivery of value to customers through AI, Data Analytics and cloud based systems.
          </p>
          </div>
            <img
              className="rounded-[15px] h-[500px] shadow-md mx-auto md:mx-0"
              src= {Transformation}
              alt="Sample"
            />
          </div>
      <div className="md:w-2/3 lg:pl-[150px] lg:pr-[100px]">
        <div className="mb-6">
          <h2 className="text-2xl font-bold mb-2 text-left">CORE FOCUS AREAS</h2>
          <p className="text-gray-700 text-left">
          At the core of our mission is an unwavering commitment to excellence in FinTech, with a specific emphasis on Digitalization. Our primary focus centers on harnessing the power of Cloud Computing, propelling innovation, and effecting meaningful change within the FinTech landscape. Driven by a profound passion for digitalization, our goal is to not only lead but also shape the future of FinTech, ushering in an era of transformative advancements and cutting-edge solutions.   
          </p>
        </div>
        <div className="mb-6">
          <h3 className="text-1xl font-bold mb-2 text-left">01. THE CLOUD</h3>
          <p className="text-gray-700 text-left">
          In the absence of a proper IT infrastructure, it is impossible to digitally transform. We assist customers with analyzing existing IT infrastructure, identifying opportunities for improvement, proposal and implementation of the relevant cloud solutions. This in turn improves flexibility and present the business with ecosystem of relevant tools.
          </p>
        </div>
        <div className="mb-6">
          <h3 className="text-1xl font-bold mb-2 text-left">02. DIGITALIZATION</h3>
          <p className="text-gray-700 text-left">
          We help organization optimize existing business operations by digitizing them. Any aspect of the business that relies on manual functions is upgraded to digital. This process improves efficiency and optimizes that business operation. This is a necessary step, for complete control and monitoring of the value chain.
          </p>
        </div>
        <div>
          <h3 className="text-1xl font-bold mb-2 text-left">03. NEW VALUE STREAMS</h3>
          <p className="text-gray-700 text-left">
          We combine the power of the cloud, Artificial Intelligence (AI) and data analytics to realize new value streams that can facilitate new business models or products. We use improvements in processes and new innovations facilitated by advanced technologies, to identify new products and services.
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default HomeAbout