import React from 'react'
import Navbar from "../Componets/Navbar";
import ContactBody from "../Componets/ContactEx";
import Footer from "../Componets/Footer";


const Contact = () => {
  return (
    <div className='Contact Container'>
      <div className='Contact-Navbar'>
        <Navbar/>
      </div>
      <div className = 'Contact-Body'>
        <ContactBody/>
      </div>
      <div className = 'Footer'>
        <Footer/>
      </div>
  </div>
  )
}

export default Contact