import axios from 'axios';
import React, { useState } from 'react'

const EmailRestAPI = () => {

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_kvcujra';
    const templateId = 'template_r6geuwg';
    const publicKey = 'GaCzItaUNbEDEaQf2';

    // Create an object with EmailJS service ID, template ID, Public Key, and Template params
    const data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        from_fname: fname,
        from_lname: lname,
        from_company: company,
        from_email: email,
        from_phone: phone,
        to_name: 'Digital FinTech',
        message: message,
      }
    };

    // Send the email using EmailJS
    try {
      const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
      console.log(res.data);
      setFname('');
      setLname('');
      setCompany('');
      setPhone('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className='bg-white  py-32'>
        <div className="flex justify-center bg-[#390cff] h-[400px] items-center">
          <div className="m-10 lg:mx-[400px]">
              <h1 className="text-3xl lg:text-4xl font-bold mb-10 lg:text-center text-[#ffffff]">CONTACT US</h1>
              <p className="mb-4 lg:text-center text-white">We’d love to hear from you. Leave your details below and a dedicated Account Executive will be in touch shortly to discuss your custom software requirements.</p>
          </div>
        </div>

      <div className="flex justify-center">
          <div className="m-10 lg:mx-[400px]">
              <div className="flex flex-col lg:text-center">
              <h3 className="text-2xl lg:text-3xl font-bold my-4 lg:text-center text-[#353535]">Our contact details</h3>
                  <p className="mb-2"><strong>Email:</strong> info@digitalfingerprint.co.za</p>
                  <p className="mb-2"><strong>Phone:</strong> 079 100 5171</p>
                  <p className="mb-2"><strong>Office Address:</strong> 546 16th Road, Randjespark, Midrand, 1685</p>
              </div>
          </div>
      </div>


      <form onSubmit={handleSubmit} className='lg:mx-[400px] bg-[#f3f3f3] p-10 rounded-2xl drop-shadow-2xl'>
        <div className="sm:col-span-2">
            <label htmlFor="first ame" className="block text-sm font-semibold leading-6 text-gray-900">
              First Name
            </label>
            <div className="relative mt-2.5">
              <input
                type="text"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                placeholder="Your First Name"
                name="first-name"
                id="first-name"
                autoComplete="firstName"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-10"
              />
          </div>
        </div>

        <div className="sm:col-span-2">
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Last Name
            </label>
            <div className="relative mt-2.5">
              <input
                type="text"
                placeholder="Last Name"
                value={lname}
                onChange={(e) => setLname(e.target.value)}
                name="last-name"
                id="last-name"
                autoComplete="lastName"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-10"
              />
          </div>
        </div>

        <div className="sm:col-span-2">
            <label htmlFor="company-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Company Name
            </label>
            <div className="relative mt-2.5">
              <input
                type="text"
                placeholder="Company Name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                name="company-name"
                id="company-name"
                autoComplete="companyName"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-10"
              />
          </div>
        </div>

        <div className="sm:col-span-2">
            <label htmlFor="email-address" className="block text-sm font-semibold leading-6 text-gray-900">
              Email Address
            </label>
            <div className="relative mt-2.5">
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email-address"
                id="email-address"
                autoComplete="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-10"
              />
          </div>
        </div>
        <div className="sm:col-span-2">
            <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
              Phone number
            </label>
            <div className="relative mt-2.5">
              <input
                type="tel"
                placeholder="Your Phone Number"
                name="phone-number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                id="phone-number"
                autoComplete="tel"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-10"
              />
          </div>
        </div>
        <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                name="message"
                placeholder="Your Message"
                id="message"
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={''}
              />
            </div>
          </div>
        <button type="submit" className='mt-10 block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>Send Email</button>
      </form>
    </div>
  )
}

export default EmailRestAPI