import React from 'react'
import Navbar from '../Componets/Navbar'
import Footer from '../Componets/Footer'
import Maintanances from '../Componets/Maintanance'

const Maintanance = () => {
  return (
    <div className='Contact Container'>
        <div className='Contact-Navbar'>
            <Navbar/>
        </div>
        <div className='Contact-Navbar mt-[100px]'>
            <Maintanances/>
        </div>
        <div className = 'Contact_Footer'>
            <Footer/>
        </div>
    </div>
  )
}

export default Maintanance