import React from 'react'

const Maintanance = () => {
  return (
    <div>
        <div className="bg-blue-500 lg:h-[50px] h-[500px] px-[50px] py-[100px] lg:p-[300px] lg:px-[400px] text-white flex items-center justify-center">
            <h1 className="lg:text-6xl text-4xl md:text-6xl font-bold lg:text-center">
                Elevate financial operations with expert Salesforce Maintenance—ensuring ongoing innovation and seamless performance in the cloud.</h1>
        </div>

        <div className="g-white h-[300px]  py-[50px] px-[50px] lg:px-[500px] text-[#313131] flex items-center justify-center">
            <p className="lg:text-2xl mt-10 font-bold text-lg lg:text-center">
                Maintain Your Business Excellence with Salesforce. Our maintenance services cover all Salesforce cloud aspects, ensuring sustained performance and maximizing the benefits of your Salesforce ecosystem for ongoing business success.
            </p>
        </div>
        <hr class="border-gray-200 sm:mx-auto dark:border-gray-700" />

        <div className="bg-white mt-[100px] lg:py-8 lg:px-[500px] px-[50px] text-[#313131]">
            <h2 className="text-4xl font-bold mb-4 lg:text-center">We help our clients maintain their Salesforce Org</h2>
            <p className="text-lg pt-4 lg:text-center">
              Salesforce is an indispensable and powerful platform for countless businesses. Yet, like any sophisticated platform, its vast capabilities can sometimes lead to unexpected pitfalls, especially when it comes to organisation (Org) management. It’s easy to take a wrong turn when navigating countless features and customisation options. Whether you’re a seasoned Salesforce veteran or a newbie, avoiding common Org mistakes can save you time, money, and numerous headaches.
            </p>
        </div>
        <div className="bg-blue-500 mt-[100px] py-8 lg:py-8 lg:px-[500px] px-[50px] text-white">
          <h2 className="text-5xl font-bold mb-4 lg:text-center">Here are some Mistakes in Salesforce Org Management</h2>
        </div>
        <div className="bg-white mt-[50px] lg:py-8 lg:px-[500px] px-[50px] text-[#313131]">
            <p>At <strong>Digital FinTech</strong>, we understand the challenges associated with Salesforce org management and are committed to helping our clients navigate them effectively. With our team of experienced Salesforce consultants, we offer comprehensive org management services tailored to each client's unique needs. From conducting regular health checks and performance assessments to providing expert guidance on customization best practices and user training, we equip our clients with the tools and knowledge they need to avoid common pitfalls and ensure the smooth operation of their Salesforce orgs. By partnering with us, clients can proactively address potential issues, optimize their Salesforce environments, and maximize the value of their investment in Salesforce.</p>
        </div>
        <div className="grid grid-cols-1 py-10 my-4 gap-4 lg:grid-cols-3 lg:gap-8 px-10 lg:px-[350px]">
            <div className="bg-blue-500 p-8 text-white mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Neglecting User Training</h2>
                <p className="text-md">
                  Effective end user training and comprehensive documentation are crucial for maximizing the benefits of Salesforce adoption, preventing data mismanagement and errors, and ensuring smooth onboarding of new team members.
                </p>
            </div>

            <div className="bg-gray-300 p-8 mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Not Testing Changes in a Sandbox</h2>
                <p className="text-md">
                  Deploying changes directly into production without prior testing can lead to unforeseen issues, disrupting user experience and eroding end-user trust, highlighting the importance of thorough testing in a sandbox environment.
                </p>
            </div>

            <div className="bg-green-500 p-8 text-white mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Failure to Maintain Proper Documentation</h2>
                <p className="text-md">
                  Neglecting to properly document processes and configurations in Salesforce can lead to confusion, increased risk, and hindered innovation, emphasizing the importance of comprehensive documentation for organizational stability and clarity.
                </p>
            </div>

            <div className="bg-yellow-300 p-8 mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Poor Change Management</h2>
                <p className="text-md">
                  Implementing changes without understanding business requirements can lead to poor adoption and wasted resources, emphasizing the importance of adopting a Change Intelligence Platform to ensure informed decision-making and alignment with genuine business needs. 
                </p>
            </div>

            <div className="bg-indigo-500 p-8 text-white mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Insufficient Data Governance</h2>
                <p className="text-md">
                  Neglecting data quality in Salesforce can lead to challenges such as duplicates and outdated records, highlighting the importance of establishing data guidelines and utilizing Salesforce features to maintain reliable data for informed decision-making.
                </p>
            </div>

            <div className="bg-purple-300 p-8 mb-8 rounded-[15px]">
                <h2 className="text-2xl font-bold mb-4">Not Keeping Up with Salesforce Releases</h2>
                <p className="text-md">
                  Neglecting to stay updated with Salesforce releases and prepare for changes can limit the potential of your Salesforce Org, highlighting the importance of proactive monitoring and testing to leverage new features and enhancements for maximum benefit.
                </p>
            </div>
        </div>

        <div className="bg-blue-500 lg:h-[50px] h-[500px] px-[50px] py-[100px] lg:p-[300px] lg:px-[400px] text-white flex items-center justify-center">
            <h1 className="lg:text-6xl text-4xl md:text-6xl font-bold lg:text-center">Our Salesforce Maintanance Services.</h1>
        </div>

        <div class="bg-white p-4 rounded-lg shadow-xl lg:px-[250px] py-8 mt-12">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-12 px-2 xl:px-12 mt-4 pb-12">
            
            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">System Health Checks</h4>
                    <p class="text-gray-600 my-2">Perform regular audits and health checks of clients' Salesforce orgs to identify any issues, performance bottlenecks, or configuration gaps. Provide recommendations for optimization and enhancement to ensure the system is running smoothly.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Salesforce Release Management</h4>
                    <p class="text-gray-600 my-2">Stay up-to-date with Salesforce releases and new features. Plan and manage the rollout of new Salesforce releases, including testing, deployment, and communication with stakeholders. Ensure compatibility with existing customizations and integrations.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Data Management</h4>
                    <p class="text-gray-600 my-2">Implement data hygiene best practices to maintain data quality and integrity within Salesforce. Perform data deduplication, cleanup, and archival to minimize data clutter and improve system performance. Regularly backup data to prevent data loss and ensure business continuity.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">User Management and Training</h4>
                    <p class="text-gray-600 my-2">Manage user access and permissions within Salesforce to ensure security and compliance. Provide user training and support to help users maximize their productivity and efficiency with Salesforce. Address user questions, issues, and feedback promptly.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Customization and Configuration Updates</h4>
                    <p class="text-gray-600 my-2">Continuously evaluate and optimize Salesforce customizations and configurations to align with evolving business needs and best practices. Implement new features, fields, workflows, and automation to enhance system functionality and user experience.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Integration Maintenance</h4>
                    <p class="text-gray-600 my-2">Monitor and maintain integrations between Salesforce and other systems or applications. Perform regular testing and troubleshooting to ensure data synchronization, integrity, and security across integrated systems.</p>
                </div>
            </div>
            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Security and Compliance Audits</h4>
                    <p class="text-gray-600 my-2">Conduct regular security audits and compliance assessments to ensure Salesforce data and processes adhere to industry standards and regulatory requirements. Implement security controls, encryption, and monitoring tools to safeguard sensitive data and prevent security breaches.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Workflow and Process Automation</h4>
                    <p class="text-gray-600 my-2">Review and optimize existing workflows and process automation rules within Salesforce. Identify opportunities to streamline business processes, reduce manual tasks, and improve operational efficiency through automation.</p>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Maintanance