import React from 'react'
import Navbar from "../Componets/Navbar";
import HeaderHero from "../Componets/HeaderHero";
import HomeAbout from "../Componets/HomeAbout";
import Footer from "../Componets/Footer";
import Salesforce from "../Componets/Salesforce";
import SalesforceService from "../Componets/SalesforceService";
import FAQ from "../Componets/FAQ";


const Home = () => {
  return (
    <div className='Home-Container'>
      <div>
        <Navbar/>
      </div>
      <div>
        <HeaderHero/>
      </div>
      <div>
        <HomeAbout/>
      </div>
      <div>
        <Salesforce/>
      </div>
      <div>
        <SalesforceService/>
      </div>
      <div>
        <FAQ/>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  )
}

export default Home