import React from 'react'

const FAQ = () => {
  return (
<div class="p-8">
    <div class="bg-white p-4 rounded-lg shadow-xl lg:px-[250px] py-8 mt-12">
        <h4 class="text-4xl font-bold text-gray-800 tracking-widest uppercase text-center">FAQ</h4>
        <p class="text-center text-gray-600 text-sm mt-2">Here are some of the frequently asked questions</p>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-12 px-2 xl:px-12 mt-4">
            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">What exactly is FinTech?</h4>
                    <p class="text-gray-600 my-2">FinTech - short for Financial Technology - refers to the integration of technology into the provision of financial services, including banking and investment, through digital platforms. This means using software programs and digital tools to make financial services more accessible and convenient for users. Instead of visiting physical bank branches or investment firms, people can access these services easily through their computers or smartphones. In essence, FinTech aims to streamline and modernize traditional financial services by leveraging technology for smoother and more efficient user experiences.</p>
                </div>
            </div>
            
            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">To whom does Digital FinTech primarily cater: individual consumers (B2C) or businesses (B2B)?</h4>
                    <p class="text-gray-600 my-2">Digital FinTech's focal point is on businesses; focusing on helping them identify areas of improvement within their operations. Our services are tailored to meet the specific needs of businesses, ensuring that they can enhance their processes and achieve greater efficiency and effectiveness.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Do I need to already be using Salesforce to access your services?</h4>
                    <p class="text-gray-600 my-2">If you're new to Salesforce or considering transitioning to it, our consultants are here to guide you through the software and seamlessly integrate it into your processes. We'll provide personalized demonstrations and support to ensure you understand how Salesforce works and how it can benefit your organization. Our goal is to help you leverage Salesforce effectively to optimize your operations and achieve your business objectives.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Do you offer a demo of your services?</h4>
                    <p class="text-gray-600 my-2">Our team of consultants is ready to tailor demonstrations to meet your unique requirements. They will illustrate how our services can seamlessly integrate with your existing business processes. Through these demonstrations, you'll gain insights into how our solutions can enhance efficiency and effectiveness within your operations.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Do you offer ongoing training for your services?</h4>
                    <p class="text-gray-600 my-2">Our consultants collaborate closely with your organization to ensure that once our services are implemented, everyone understands how they function. We focus on comprehensive training and communication strategies to integrate the new processes seamlessly into your company culture and workflow. This approach ensures that every member of your organization is equipped to embrace and utilize the new tools effectively, maximizing the benefits of our services across your entire operation.

                    If further training is required, our consultants are readily available to provide assistance. They will work closely with you to understand your specific training needs and then develop a tailored program accordingly. This program will be designed to address the areas where additional support is needed, ensuring that it aligns perfectly with your requirements. Moreover, the duration of the training will be optimized to provide thorough understanding without causing disruptions to your operations. Our goal is to empower your team with the knowledge and skills necessary to fully leverage our services within your organization.</p>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <h4 class="text-xl font-bold text-gray-700">How do I get in touch with one of your consultants?</h4>
                    <p class="text-gray-600 my-2">Our team of consultants is poised to address your needs promptly. Simply visit our contact page, and a dedicated consultant will be assigned to assist you. They will work closely with you to understand your requirements and provide personalized guidance and support every step of the way. Your satisfaction and success are our top priorities, and we're committed to ensuring that you receive the assistance you need to achieve your goals effectively.</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default FAQ